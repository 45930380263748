.fc-h-event {
    border: unset !important;
    background-color: unset !important;
}

.fc-col-header-cell {
    background-color: #163151 !important;
}

.fc-col-header-cell-cushion  {
    color: #FFFFFF !important;
}

.fc-daygrid-event {
    cursor: pointer;
}

.fc-daygrid-day-top {
    align-items: center;
    justify-content: center;
}

.fc-daygrid-day-number {
    text-decoration: unset !important;
    font-size: 13px;
}

.parsha-info {
    white-space: pre-wrap;
}

/* .fc-daygrid-day-top {
    display: none !important;
} */

@media screen and (max-width: 700px) {
    .fc-daygrid-day-number {
        margin-top: unset !important;
        font-size: 10px !important
    }

    .fc-daygrid-day-top {
        padding: 8px 2px !important;
    }
}