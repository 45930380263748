.donate-card {
    background: #b1ff96;
}

.donate-container {
    background: 'white';
    z-index: 999;
    margin-left: '2px';
    margin-right: '2px'
}

.sponsor-text {
    color: #908a89 !important;
    font-size: 20px;
    font-weight: 600;
}

.price-text {
    color: black !important;
    font-size: 25px;
    font-weight: 600;
}

.sponsored-by {
    color: #616753;
    font-weight: 400;
}

.wrap-text {
    white-space: pre-wrap;
}

.fc .fc-daygrid-day-top {
    display: inline-block;
    padding: 2px 11px;
}

.fc .fc-daygrid-day-number {
    font-size: 16px;
}

.hebrew-full-date {
    position: absolute;
    top: 0;
    right: -5px;
    font-size: 16px;
}

.fc .fc-daygrid-day-top {
    position: absolute;
}

.container.donate-container {
    padding-top: 20px;
}

p.float-end.sponsored-by {
    position: absolute;
    top: 0;
    right: 0;
}

.fc-daygrid-dot-event {
    min-height: 143px !important;
}

.container.donate-card {
    min-height: 143px;
}

p.sponsored-by.m-0.wrap-text, p.sponsored-by.m-0, p.m-0.wrap-text {
    text-align: center;
}

.reactJewishDatePicker .monthWrapper.open {
    transform: scaleY(1);
    z-index: 9;
}

.reactJewishDatePicker .selectedDate {   
    width: 200px;
    position: absolute !important;
    right: 200px;
    top: 10px;
}