
a {
    color: unset !important;
}

h3 {
    color: white !important;
}

h6 {
    color: white !important;
}